body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.3;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



.cropper-wrap-box {
  background-color: white;
  /* height: 75vh !important; */
  /* overflow:'visible'; */


}

.cropper-modal {
  background-color: white !important;
  /* height: 75vh !important; */

  /* overflow:'visible'; */

}

.container {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 35vh;

  padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

/* Then style the iframe to fit in the container div with full height and width */
.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height:100%;
}

/* .cropper-container {
  height: 75vh !important;
  overflow:'visible';

}


.cropper-crop-box {
  height: 75vh !important;
  overflow:'visible';

} */
